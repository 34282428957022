import React, { Component } from 'react';
import { Button, Card, CardBody, Col, CustomInput, Input, Label, Modal, ModalBody, ModalHeader, Row, Badge, Form } from 'reactstrap';
import { deleteAPI, getAPI, postAPI, postMultiPartAPI, putAPI } from '../Base/API';
import { BlankCard, StyledCard, MultiLangText, getMultiLangEmpty } from '../Base/Common/CommonUIComponents';
import { getRebateActions, getPromoteTypes, getTaxCodes, processMultilingualTexts } from '../Base/Common/ReferenceDataFunctions';
import DragAndDrop from "../Base/Common/DragAndDrop";
import { handleNotification } from "../Base/Common/Notification";
import { VoucherPreview } from "./VoucherPreview";
import CustomSelect from "../Base/Common/CustomSelect";
import { FormattedMessage, injectIntl } from 'react-intl';
import { ImageDragAndDrop } from "./ImageDragAndDrop";
import Authorization from '../Base/Authorization';
import VoucherConfigStep1 from "./VoucherConfigStep1";
import VoucherConfigStep8 from './VoucherConfigStep8';


export class CardConfigWizard extends Component {
    static displayName = CardConfigWizard.name;
    _isMounted = false;
    constructor(props) {
        super(props);
        this.getConfigDetails = this.getConfigDetails.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.toggleRebateModal = this.toggleRebateModal.bind(this);
        this.editRebate = this.editRebate.bind(this);
        this.toggleModalPreview = this.toggleModalPreview.bind(this);
        this.handleSwitched = this.handleSwitched.bind(this);
        this.updateTerm = this.updateTerm.bind(this);
        this.moveImages = this.moveImages.bind(this);
        this.formRebate = React.createRef();

        this.state = {
            block: false,
            blockTemplateImages: false,
            isDragMode: false,
            steps: [
                { step: '1', active: true, enabled: true },
                { step: '2', active: false, enabled: true },
                { step: '3', active: false, enabled: true },
                { step: '4', active: false, enabled: true },
                { step: '5', active: false, enabled: true },
                { step: '6', active: false, enabled: true },
                { step: '7', active: false, enabled: true },
                { step: '8', active: false, enabled: true }
            ],
            files: [],
            hotelList: [],
            addedHotels: [],
            voucherRebate: this.getNewVoucherRebate(),
            voucherConfig: {
                multiLingual: getMultiLangEmpty(),
                isPaymentMode: false
            },
            voucherRebateList: [],
            voucherHotelList: [],
            voucherImageList: [],
            voucherTermsList: [],
            voucherChannelList: [],
            ReveneuCatList: [],
            templateImages: [],
            templateImage: null,
            vatModal: false
        }
    }

    getNewVoucherRebate() {
        const voucherRebate = {
            id: null,
            multiLingual: getMultiLangEmpty()
        };
        return voucherRebate;
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.getConfigDetails(this.props.match.params.id)
        }
        else {
            this.setState({ block: false });
        }
        this.getHotels();
        this.getTerms();
        this.getChannels();
        this.getReveneuCategories();
    }

    next(val) {
        var stateCopy = Object.assign({}, this.state);
        stateCopy.steps = stateCopy.steps.slice();
        for (var i = 0; i < stateCopy.steps.length; i++) {
            if (stateCopy.steps[i].step === val) {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = true;
                stateCopy.steps[i].enabled = true;
            }
            else {
                stateCopy.steps[i] = Object.assign({}, stateCopy.steps[i]);
                stateCopy.steps[i].active = false;
            }
        }
        this.setState(stateCopy);
    }


    getTerms() {
        this.setState({ blockCfg: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCfg: false });

                return;
            }
            if (data) {
                data.response.forEach(processMultilingualTexts);
                this.setState({ termsList: data.response, blockCfg: false });
            }
        }, '/api/Voucher/v1/Config/getTermsAndConditions');
    }

    getChannels() {
        this.setState({ blockCfg: true });
        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, blockCfg: false });
                return;
            }
            if (data) {

                this.setState({ channelList: data.response, blockCfg: false });
            }
        }, '/api/Voucher/v1/Config/getChannels');
    }

    getConfigDetails(id) {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState({ error: errorMessage, block: false });
                }
                else if (data.response && data.response.length > 0) {
                    const voucher = data.response[0];
                    processMultilingualTexts(voucher);
                    this.populateVoucherTerms(voucher.voucherTerms, voucher.id);
                    voucher.voucherImages.forEach((img, idx) => {
                        if (img.index === null) {
                            img.index = idx;
                        }
                    });
                    voucher.voucherRebates.forEach(processMultilingualTexts);

                    this.setState({
                        voucherConfig: voucher,
                        voucherRebateList: voucher.voucherRebates,
                        voucherHotelList: voucher.validHotels,
                        voucherImageList: voucher.voucherImages.sort((img1, img2) => img1.index - img2.index),
                        voucherTermsList: voucher.voucherTerms,
                        voucherChannelList: voucher.channels,
                        block: false
                    });
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
        }, '/api/Voucher/v1/Config/getVoucherType/' + id);
    }

    populateVoucherTerms(voucherTerms, voucherTypeId) {
        this.checkVoucherTerms(voucherTerms, "Included", voucherTypeId);
        this.checkVoucherTerms(voucherTerms, "Excluded", voucherTypeId);
    }

    checkVoucherTerms(voucherTerms, type, voucherTypeId) {
        const voucherTermsIncluded = voucherTerms
            .filter(term => term.type === type)
            .map(processMultilingualTexts);
        if (voucherTermsIncluded.length === 0) {
            voucherTerms.push({
                voucherTypeId: voucherTypeId,
                code: null,
                type: type,
                multiLingual: getMultiLangEmpty()
            });
        }
    }

    toggleTab(id) {
        this.setState({ activeTab: id });
    }

    toggleRebateModal() {
        this.setState(prevState => ({
            modal: !prevState.modal,
            voucherRebate: this.getNewVoucherRebate()
        }));
    }

    toggleModal(modal) {
        this.setState(prevState => ({
            [modal]: !prevState[modal]
        }));
    }

    editRebate(rebate) {
        this.setState({
            modal: true,
            voucherRebate: rebate
        });
    }

    toggleModalPreview() {
        this.setState(prevState => ({
            modalPreview: !prevState.modalPreview
        }));
    }

    handleSwitched(f, evt) {
        const name = evt.target.name;
        const value = evt.target.checked;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value

            },
        }));
    }

    handleCheck(evt) {
        let name = evt.target.name;
        let value = evt.target.checked;
        if (value === true) {
            this.setState({ addedHotels: [...this.state.addedHotels, name] });
        }
        else {
            this.setState({ addedHotels: [...this.state.addedHotels.filter(el => el !== name)] });
        }
    }

    handleDatesChange = (type, start, end) => {
        if (type === 'r') {
            this.setState(prevState => ({
                ...prevState,
                voucherRebate: {
                    ...prevState.voucherRebate,
                    saleValidTo: end,
                    saleValidFrom: start

                },
            }));
        } else {
            this.setState(prevState => ({
                ...prevState,
                voucherConfig: {
                    ...prevState.voucherConfig,
                    saleValidTo: end,
                    saleValidFrom: start

                },
            }));
        }
    }

    saveVoucher = (e) => {
        e.preventDefault();

        if (this.state.voucherConfig.isPaymentMode === false && this.state.voucherConfig.payedAmount > 0 &&
            (!this.state.voucherConfig.voucherTypeVat || this.state.voucherConfig.voucherTypeVat.reduce((acc, curr) => acc + parseFloat(curr.amount), 0).toFixed(2) !== parseFloat(this.state.voucherConfig.payedAmount).toFixed(2))) {
            handleNotification({ data: {} }, <FormattedMessage id="EditVoucher.SumOfVatAmountsMustBeEqualToPayedAmount" />, '', 'info');
            return;
        }

        this.setState({ block: true });

        let temp = this.state.voucherConfig;
        delete temp['validHotels'];
        delete temp['voucherRebates'];
        if (temp.id === undefined)
            temp.id = null

        if (this.state.voucherConfig.tec) {
            temp.termsConditionsId = this.state.voucherConfig.tec.id;
        }

        let payload = { request: temp };

        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;
            const errorMessage = [];
            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                if (data.errors && data.errors.length > 0) {
                    handleNotification(data);
                    this.setState(({ block: false, error: errorMessage }));
                    return;
                }
                if (data.response && data.response.length > 0) {
                    const voucherTermsList = [...this.state.voucherTermsList];
                    if (voucherTermsList.length === 0) {
                        this.populateVoucherTerms(voucherTermsList, data.response[0].id);
                        this.setState({ voucherTermsList });
                    }
                    this.props.history.push({ pathname: `/CardConfigWizard/${data.response[0].id}` });
                    handleNotification(data, <FormattedMessage id="CardConfigWizard.ConfigSaved" />, <FormattedMessage id="generic.success" />);

                    const voucherConfig = data.response[0];
                    voucherConfig.multiLingual = Object.assign(voucherConfig.multiLingual, temp.multiLingual);

                    this.setState({ voucherConfig }, this.getConfigDetails(data.response[0].id));
                }
                else {
                    this.setState({ error: errorMessage, block: false });
                }
            }
            else {
                this.setState({ error: errorMessage, block: false });
            }
        }, '/api/Voucher/v1/Config/setVoucherType', payload);
    }

    saveRebate(e) {
        e.preventDefault();

        if (this.formRebate.current.reportValidity()) {
            this.setState({ block: true });
            let temp = this.state.voucherRebate;

            if (temp.id === undefined) {
                temp.id = null;
            }
            temp.voucherTypeId = this.state.voucherConfig.id;

            const payload = { request: temp };

            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.RebateAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState({ block: false, voucherRebate: this.getNewVoucherRebate() }, () => {
                        this.toggleRebateModal();
                        this.getConfigDetails(this.state.voucherConfig.id)
                    });
                }
            }, '/api/Voucher/Voucher/v1/voucherrebate', payload);
        }
    }

    removeRebate(id) {
        this.setState({ block: true });

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.RebateRemoved" />, <FormattedMessage id="EditVoucher.Success" />);

                this.setState({ block: false }, () => this.getConfigDetails(this.state.voucherConfig.id));
            }
        }, '/api/Voucher/Voucher/v1/voucherrebate/' + id);

    }

    getHotels() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                this.setState({ hotelList: data.response[0], block: false });
            }
        }, '/api/Voucher/Hotel/v1/hotel');

    }

    updateForm = (f, evt) => {
        let name = evt.target.name;
        let value = evt.target.value;

        this.setState(prevState => ({
            ...prevState,
            [f]: {
                ...prevState[f],
                [name]: value
            },
        })/*, () => this.updateTemplateImages()*/);
    }

    updateComboForm(f, val, evt) {
        if (evt != undefined) {
            let name = evt.name;
            let value = val ? val.value : null;

            this.setState(prevState => ({
                ...prevState,
                [f]: {
                    ...prevState[f],
                    [name]: value

                },
            }));
        }
    }

    manageHotel(hotelId, evt) {

        this.setState({ block: true });

        let payload = {
            "request": {
                "id": this.state.voucherConfig.id,
                "hotels": [
                    hotelId
                ]
            }
        }

        if (evt.target.checked) {
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.HotelAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState({ voucherHotelList: data.response[0].hotels, block: false });
                }
            }, '/api/Voucher/Voucher/v1/vouchervalidity', payload);

        }
        else {
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.HotelRemoved" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState({ voucherHotelList: data.response[0].hotels, block: false });
                }
            }, '/api/Voucher/Voucher/v1/removevouchervalidity', payload);
        }

    }

    getFilteredHotels() {
        let res = [];
        if (this.state.hotelList.hotels) {
            res = this.state.hotelList.hotels.filter(el => this.state.voucherHotelList.find(item => item.guid === el.hotelId) === undefined);
        }
        return res === undefined ? [] : res;
    }

    handleDrop = (files) => {
        let fileList = this.state.files;
        for (var i = 0; i < files.length; i++) {
            if (files[0] && files[0].size <= 1048576) { // <= 1MB
                fileList.push(URL.createObjectURL(files[i]));
            }
            else {
                handleNotification('', <FormattedMessage id="CardConfigWizard.PleaseSelectAFileLessThan" />, <FormattedMessage id="CardConfigWizard.SelectedFileIsTooBig" />, 'info');
                return;
            }
        }

        const formData = new FormData();
        formData.append('file', files[0]);

        var url = '/api/Voucher/v1/Config/UploadImages/' + this.state.voucherConfig.id;

        postMultiPartAPI(result => {
            const { data, error } = result;
            var errorMessage = []
            if (error) {

                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });

                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                errorMessage.push({ message: "Photo Updated sucessfuly", messageType: 'success' });

                this.setState(prevState => ({ block: false, voucherImageList: [...prevState.voucherImageList, data.response[0]] }));
            }
        }, url, formData);
    }

    handleDropTEC = (e) => {
        let evtData = JSON.parse(e.getData('TEC'));
        let req = {
            request: {
                id: this.state.voucherConfig.id,
                termsConditionsId: evtData.id
            }
        }

        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.TermsAndConditionsUpdated" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState(prevState => ({
                    block: false,
                    ...prevState,
                    voucherConfig: {
                        ...prevState.voucherConfig,
                        tec: evtData

                    },
                }));
            }
        }, '/api/Voucher/v1/Config/setVoucherTypeOnlineProp', req);
    }

    deleteImage(id) {

        deleteAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.ImageDeleted" />, <FormattedMessage id="EditVoucher.Success" />);

                this.setState(prevState => ({ block: false, voucherImageList: prevState.voucherImageList.filter(el => el.id !== data.response[0].id) }));
            }
        }, '/api/Voucher/v1/Config/DeleteImage/' + id);


    }

    manageChannel(id, ciid, evt) {
        this.setState({ block: true });
        if (evt.target.checked) {
            postAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.ChannelAdded" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState(prevState => ({ block: false, voucherChannelList: [...prevState.voucherChannelList.filter(el => el.id !== data.response[0].id), data.response[0]] }));
                }
            }, '/api/Voucher/v1/Config/addChannelInstance/' + this.state.voucherConfig.id + '/' + id);
        } else {
            deleteAPI(result => {
                const { data, error } = result;

                if (error) {
                    var errorMessage = [];
                    errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                    this.setState({ error: errorMessage, block: false });

                    return;
                }
                if (data) {
                    handleNotification(data, <FormattedMessage id="EditVoucher.ChannelRemoved" />, <FormattedMessage id="EditVoucher.Success" />);
                    this.setState(prevState => ({ block: false, voucherChannelList: [...prevState.voucherChannelList.filter(el => el.id !== data.response[0].id)] }));
                }
            }, '/api/Voucher/v1/Config/removeChannelInstance/' + ciid);
        }

    }

    changeTerms(type, evt) {

        let t = type;
        let value = evt.target.value;

        this.setState(prevState => ({
            block: false,
            ...prevState,
            voucherTermsList:
                [...prevState.voucherTermsList.filter(el => el.type !== type),
                { description: value, type: t, voucherTypeId: this.state.voucherConfig.id }

                ]

        }
        ));
    }

    saveTerms(type, evt) {
        const req = { request: this.state.voucherTermsList.find(el => el.type === type) };
        this.setState({ block: true });
        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.TermsSaved" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ block: false });
            }
        }, '/api/Voucher/v1/Config/addVoucherTypeTerms', req);
    }

    getReveneuCategories() {
        this.setState({ block: true });
        getAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {

                let arr = [];

                data.response.map(el => arr.push({ label: el.description, value: el.id }));

                this.setState({ ReveneuCatList: arr, block: false });
            }
        }, '/api/Voucher/v1/Config/ReveneuCategories');

    }

    savePromote() {
        this.setState({ block: true });
        let req = {
            "request": {
                "id": this.state.voucherConfig.id,
                "promote": this.state.voucherConfig.promote,
                "promoteType": this.state.voucherConfig.promoteType,
                "promoCode": this.state.voucherConfig.promoCode || null
            }
        }


        postAPI(result => {
            const { data, error } = result;

            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });

                return;
            }
            if (data) {
                handleNotification(data, <FormattedMessage id="EditVoucher.PromotionConditionsUpdated" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ block: false });
            }
        }, '/api/Voucher/v1/Config/setVoucherTypeOnlineProp', req);


    }

    udpateText = (evt, lang) => {
        const multiLingual = [...this.state.voucherConfig.multiLingual];
        const idx = multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));

        if (idx >= 0) {
            const ml = multiLingual[idx];
            ml[evt.target && evt.target.name] = evt.target && evt.target.value;
            this.setState(prevState => ({
                ...prevState,
                voucherConfig: {
                    ...prevState.voucherConfig,
                    multiLingual: multiLingual
                }
            }));
        }
    }

    updateRebateTerms(evt, lang) {
        const multiLingual = [...this.state.voucherRebate.multiLingual];
        const idx = multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));
        if (idx >= 0) {
            const ml = multiLingual[idx];
            ml[evt.target && evt.target.name] = evt.target && evt.target.value;
            this.setState(prevState => ({
                ...prevState,
                voucherRebate: {
                    ...prevState.voucherRebate,
                    multiLingual: multiLingual
                }
            }));
        }
    }

    updateIncludedTerm(evt, lang) {
        this.updateTerm(evt, lang, "Included");
    }

    updateExcludedTerm(evt, lang) {
        this.updateTerm(evt, lang, "Excluded");
    }

    updateTerm(evt, lang, type) {
        const voucherTermsList = [...this.state.voucherTermsList];
        const voucherTerm = voucherTermsList.find(term => term.type === type);
        const multiLingual = voucherTerm && voucherTerm.multiLingual;
        const idx = multiLingual && multiLingual.indexOf(multiLingual.find(ml => ml.language === lang));
        if (idx >= 0) {
            const ml = multiLingual[idx];
            ml[evt.target && evt.target.name] = evt.target && evt.target.value;
            this.setState(({ voucherTermsList }));
        }
    }

    moveImages(e, k) {
        e.preventDefault();
        e.stopPropagation();
        const { voucherImageList, endIdx, voucherConfig } = this.state;
        voucherImageList[k].index = endIdx;
        voucherImageList[endIdx].index = k;
        const body = {
            request: voucherImageList
        };
        this.setState({ block: true });
        putAPI(result => {
            const { data, error } = result;
            const errorMessage = [];

            if (error) {
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                this.setState({ error: errorMessage, block: false });
                return;
            }
            if (data && data.response) {
                handleNotification(data, <FormattedMessage id="EditVoucher.ReorderImagesSuccess" />, <FormattedMessage id="EditVoucher.Success" />);
                this.setState({ voucherImageList: data.response.sort((img1, img2) => img1.index - img2.index) });
            }
            this.setState({ error: errorMessage, block: false });
        }, `/api/Voucher/v1/Config/ReorderImages/${voucherConfig.id}`, body);
    }


    handleVoucherTypeVat = (evt, vat) => {
        evt.preventDefault();
        const voucher = { ...this.state.voucherConfig };
        let vatList = voucher.voucherTypeVat ? voucher.voucherTypeVat : []

        if (vat) {
            vatList = vatList.filter(v => v !== vat);
        }
        else {
            vatList.push({ taxCode: this.state.vatTaxCode, amount: this.state.vatAmount });
        }

        voucher.voucherTypeVat = vatList;

        this.setState({ voucherConfig: voucher }, () => { if (!vat) this.toggleModal('vatModal') });
    }

    render() {
        function getTerm(voucherTermsList, type) {
            const term = voucherTermsList && voucherTermsList.find(term => term.type === type);
            return term ? term.multiLingual : []
        }

        function getTecMultilingual(termsList, tec) {
            const term = termsList && tec && termsList.find(term => term.id === tec.id);
            return term ? term.multiLingual : []
        }

        const taxCodes = getTaxCodes();


        return (
            <>
                <Modal isOpen={this.state.modalPreview} toggle={this.toggleModalPreview} fade={false} className="modal-xl" >
                    <ModalBody className="modal-xl p-0">
                        <VoucherPreview
                            voucherConfig={this.state.voucherConfig}
                            voucherRebateList={this.state.voucherRebateList}
                            voucherHotelList={this.state.voucherHotelList}
                            voucherImageList={this.state.voucherImageList}
                            voucherTermsList={this.state.voucherTermsList}
                            intl={this.props.intl}
                        />
                    </ModalBody>
                </Modal>
                <Modal isOpen={this.state.modal} toggle={this.toggleRebateModal} fade={false} className="modal-xl" body >
                    <StyledCard title="EditVoucher.AddRebate" error={this.state.error} block={this.state.block}>
                        <Authorization
                            perform="cardConfigWizard:saveService"
                            yes={() => (
                                <Row className="mb-2">
                                    <Col className="text-right">
                                        <Button className="btn-host btn-sm" onClick={this.saveRebate.bind(this)}> <i className="fas fa-save " /></Button>
                                    </Col>
                                </Row>
                            )}
                            no={() => <div></div>}
                        />
                        <form ref={this.formRebate}>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="EditVoucher.Code" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="EditVoucher.Code" >
                                        {
                                            placeholder => <Input type="text" placeholder={placeholder} required minLength="1" maxLength="50" name="code" value={this.state.voucherRebate.code} onChange={this.updateForm.bind(this, 'voucherRebate')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                                <Col className="col-2">
                                    <Label><FormattedMessage id="EditVoucher.MaxQuantity" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="EditVoucher.MaxQuantity" >
                                        {
                                            placeholder => <Input type="number" min="1" max="10000" required placeholder={placeholder} name="quantity" value={this.state.voucherRebate.quantity} onChange={this.updateForm.bind(this, 'voucherRebate')} />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="EditVoucher.RevenewCategory" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect style={{ zIndex: '9999' }} required options={this.state.ReveneuCatList} value={this.state.ReveneuCatList.find(el => el.value === this.state.voucherRebate.revenueCategoryId)} name={'revenueCategoryId'} onChange={this.updateComboForm.bind(this, 'voucherRebate')} placeholder={<FormattedMessage id="EditVoucher.RevenewCategory" />} />
                                </Col>
                                <Col className="col-2">
                                    <Label><FormattedMessage id="CardConfigWizard.Action" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect
                                        options={getRebateActions(this.props.intl)}
                                        value={getRebateActions(this.props.intl).find(opt => opt.value === this.state.voucherRebate.action)}
                                        name={'action'}
                                        onChange={this.updateComboForm.bind(this, 'voucherRebate')}
                                        placeholder=""
                                        required
                                    />
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="EditVoucher.Description" /></Label>
                                </Col>
                                <Col className="col-10">
                                    <MultiLangText textList={this.state.voucherRebate.multiLingual} udpateTerms={this.updateRebateTerms.bind(this)} />
                                </Col>
                            </Row>
                        </form>
                    </StyledCard>
                </Modal>
                <Modal isOpen={this.state.vatModal} toggle={() => this.toggleModal('vatModal')} fade={false} className="modal-xl" >
                    <ModalHeader toggle={() => this.toggleModal('vatModal')} className="py-2">
                        <FormattedMessage id="EditVoucher.AddRebate" />
                    </ModalHeader>
                    <ModalBody className="modal-xl">
                        <Form onSubmit={(e) => this.handleVoucherTypeVat(e)}>
                            <Row className="mb-2">
                                <Col className="text-right">
                                    <Button className="btn-host btn-sm" type="submit"> <i className="fas fa-save " /></Button>
                                </Col>
                            </Row>
                            <Row className="mb-2">
                                <Col className="col-2">
                                    <Label><FormattedMessage id="EditVoucher.TaxCode" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <CustomSelect required
                                        options={this.state.voucherConfig.voucherTypeVat ? taxCodes.filter(tc => this.state.voucherConfig.voucherTypeVat.some(el => el.taxCode === tc.value) === false) : taxCodes}
                                        onChange={(combo) => this.setState({ vatTaxCode: combo ? combo.value : null })}
                                        placeholder={<FormattedMessage id="EditVoucher.TaxCode" />}
                                    />
                                </Col>
                                <Col className="col-2">
                                    <Label><FormattedMessage id="EditVoucher.Amount" /></Label>
                                </Col>
                                <Col className="col-4">
                                    <FormattedMessage id="EditVoucher.Amount" >
                                        {placeholder =>
                                            <Input type="number" min="1" step=".01"
                                                required placeholder={placeholder}
                                                onChange={(e) => this.setState({ vatAmount: e.target.value })}
                                            />
                                        }
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </Modal>

                <Row className="align-items-center">
                    <Col className="py-1 align-middle">
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[0].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[0].enabled === true ? ' ' : ' disabled ')} onClick={() => this.next('1')}>
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step1" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.Card" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2 "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[1].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[1].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.isPaymentMode === false && this.state.voucherConfig.id ? false : true} onClick={() => this.next('2')} >
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step2" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.Rebates" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2  "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[2].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[2].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.id === undefined ? true : false} onClick={() => this.next('3')} >
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step3" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.PointOfSales" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2  "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[3].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[3].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.id === undefined ? true : false} onClick={() => this.next('4')} >
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step4" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.TermsConditions" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2  "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[4].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[4].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.id === undefined ? true : false} onClick={() => this.next('5')} >
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step5" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.Includions" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2  "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[5].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[4].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.id === undefined ? true : false} onClick={() => this.next('6')} >
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step6" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.Images" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2  "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[6].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[5].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.id === undefined ? true : false} onClick={() => this.next('7')}>
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step7" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.Promote" />
                            </div>
                        </Badge>
                        <i className="fas fa-chevron-right fa-2x text-secondary align-middle mr-2  "></i>
                        <Badge tag="button" style={{ minWidth: '100px' }} className={'btn align-middle mr-2 py-2 border ' + (this.state.steps && this.state.steps[7].active === true ? 'btn-host' : 'bg-light text-secondary') + (this.state.steps && this.state.steps[6].enabled === true ? ' ' : ' disabled ')} disabled={this.state.voucherConfig.id === undefined ? true : false} onClick={() => this.next('8')}>
                            <div className="mb-1">
                                <FormattedMessage id="CardConfigWizard.Step8" />
                            </div>
                            <div>
                                <FormattedMessage id="CardConfigWizard.Template" />
                            </div>
                        </Badge>
                    </Col>
                    <Col className="col-1 text-right">
                        <Button className="btn-host btn-sm mr-1" onClick={this.toggleModalPreview} disabled={this.state.voucherChannelList && this.state.voucherChannelList.find(el => el.name === 'Online') === undefined}>
                            <i className="far fa-eye" />
                        </Button>
                    </Col>
                </Row>
                <div className="mb-2"></div>
                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[0].active === true ? 'block' : 'none'), height: '100%' }}>
                    <VoucherConfigStep1
                        block={this.state.block}
                        saveVoucher={this.saveVoucher}
                        voucherConfig={this.state.voucherConfig}
                        updateForm={this.updateForm}
                        handleDatesChange={this.handleDatesChange}
                        udpateText={this.udpateText}
                        handleSwitched={this.handleSwitched}
                        toggleModal={this.toggleModal}
                        handleVoucherTypeVat={this.handleVoucherTypeVat}
                        taxCodes={taxCodes}
                        updateComboForm={this.updateComboForm.bind(this, 'voucherConfig')}
                    />
                </Card>


                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[1].active === true ? 'block' : 'none'), height: '100%' }}>

                    <BlankCard title='EditVoucher.Rebates' block={this.state.block} >
                        <Row>
                            <Col>
                                <h5><FormattedMessage id="EditVoucher.Rebates" /></h5>
                            </Col>
                            <Col className="text-right">
                                <Authorization
                                    perform="cardConfigWizard:addService"
                                    yes={() => (
                                        <Button className="btn-host btn-sm" onClick={() => this.toggleModal('modal')} disabled={this.state.voucherConfig.id === undefined ? true : false}>
                                            <i className="fas fa-plus " />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>
                        <hr className="my-1 border-host" />
                        <Row className="mt-2">
                            {
                                this.state.voucherRebateList && this.state.voucherRebateList.map((item, k) =>
                                    <Col className="col-3" key={k}>
                                        <Card>
                                            <CardBody className="pt-2">
                                                <Row className="mb-2">
                                                    <Col className="col-1" />
                                                    <Col className="text-center pr-0"><b>{item.code}</b></Col>
                                                    <Col className="col-3 text-right">
                                                        <span style={{ cursor: 'pointer' }} className="mr-1" onClick={() => this.editRebate(item)}>
                                                            <i className="fas fa-edit color-host" />
                                                        </span>

                                                        <Authorization
                                                            perform="cardConfigWizard:removeService"
                                                            yes={() => (
                                                                <span style={{ cursor: 'pointer' }}>
                                                                    <i className="fas fa-times color-host ml-1" onClick={this.removeRebate.bind(this, item.id)} />
                                                                </span>
                                                            )}
                                                            no={() => <div></div>}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row className="mb-4">
                                                    <Col>
                                                        <MultiLangText key={k} textList={item.multiLingual} renderInCard={true} />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className=""><b><FormattedMessage id="EditVoucher.Quantity" /> </b></Col>
                                                    <Col className="text-right"><b><FormattedMessage id="CardConfigWizard.Action" /> </b></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="">{item.quantity}</Col>
                                                    <Col className="text-right"><FormattedMessage id={`ReferenceDataFunctions.${item.action}`} /></Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )
                            }
                        </Row>
                    </BlankCard>
                </Card>
                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[2].active === true ? 'block' : 'none'), height: '100%' }}>
                    <Row>
                        <Col>
                            <BlankCard block={this.state.block} >
                                <Row >
                                    <Col>
                                        <h5><FormattedMessage id="EditVoucher.ValidHotels" /></h5>
                                    </Col>
                                    <Col className="text-right">
                                    </Col>
                                </Row>
                                <hr className="my-1 border-host" />
                                {
                                    this.state.hotelList && this.state.hotelList.hotels && this.state.hotelList.hotels.map((item, k) =>
                                        <Row key={k}>
                                            <Col>
                                                <CustomInput type="switch" label={item.name2} checked={this.state.voucherHotelList.find(x => x.guid === item.hotelId) === undefined ? false : true} name={'hotelid' + k} id={'hotelid' + k} onChange={this.manageHotel.bind(this, item.hotelId)} />
                                            </Col>
                                        </Row>
                                    )
                                }


                            </BlankCard>
                        </Col>
                        <Col>
                            <BlankCard block={this.state.block} >
                                <Row >
                                    <Col>
                                        <h5><FormattedMessage id="CardConfigWizard.SalesChannels" /></h5>
                                    </Col>
                                    <Col className="text-right">
                                    </Col>
                                </Row>
                                <hr className="my-1 border-host" />
                                <Row className="mb-2">
                                    <Col>

                                        {this.state.channelList && this.state.channelList.map((el, k) => {
                                            let chan = this.state.voucherChannelList && this.state.voucherChannelList.find(pos => pos.channelConfigId === el.id);
                                            return (
                                                <CustomInput key={k} checked={chan !== undefined ? true : false} type="switch" label={el.name} id={'channel' + k} onChange={this.manageChannel.bind(this, el.id, chan && chan.id)}></CustomInput>
                                            )
                                        })
                                        }

                                    </Col>
                                </Row>
                            </BlankCard>
                        </Col>
                    </Row>

                </Card>
                <Card className="border-0 " style={{ display: (this.state.steps && this.state.steps[3].active === true ? 'block' : 'none'), height: '100%' }}>
                    <BlankCard block={this.state.block} >
                        <Row className="mt-2 ">
                            <Col>
                                <h5><FormattedMessage id="CardConfigWizard.TermsConditions" /></h5>
                            </Col>
                        </Row>
                        <hr className="my-1 border-host" />

                        <Row className="my-2">
                            {this.state.voucherConfig.sold < 1 ?
                                <Col><h6><FormattedMessage id="EditVoucher.Selected" /> </h6>
                                    <hr className="my-1" />
                                </Col>
                                : ''}
                            <Col><h6><FormattedMessage id="EditVoucher.TermsAndConditions" /></h6>
                                <hr className="my-1" />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className=" p-0">
                                <div className="py-2 px-3">
                                    <MultiLangText textList={getTecMultilingual(this.state.termsList, this.state.voucherConfig.tec)} renderInCard={true} />
                                </div>

                                <Authorization
                                    perform="cardConfigWizard:saveTEC"
                                    yes={() => this.state.voucherConfig.sold < 1 && (
                                        <DragAndDrop handleDrop={this.handleDropTEC} divStyle={{ display: 'inline-block', position: 'absolute', top: '0', left: '6px', width: '98%', height: '35%' }}>
                                            <div className="p-2" style={{ height: 100, minWidth: 300, whiteSpace: 'break-spaces' }}>
                                                {/*
                                                this.state.voucherConfig.tec && this.state.voucherConfig.tec.description && ReactHtmlParser(this.state.voucherConfig.tec && '<li>' + this.state.voucherConfig.tec.description.replace(/\n/g, "</li><li>") + '</li>')
                                                */}

                                            </div>
                                        </DragAndDrop>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                            {this.state.voucherConfig.sold < 1 ?
                                <Col className="col-6">
                                    {
                                        this.state.termsList && this.state.termsList.map((el, k) =>
                                            <Row className="mb-1" key={k}>
                                                <Col>
                                                    <Card>
                                                        <CardBody style={{ whiteSpace: 'break-spaces' }} draggable="true" onDragStart={(event) => event.dataTransfer.setData("TEC", JSON.stringify(el))} >
                                                            {el.code}
                                                            <MultiLangText key={k} textList={el.multiLingual} />
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        )
                                    }
                                </Col>
                                : ''}
                        </Row>
                    </BlankCard>
                </Card>
                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[4].active === true ? 'block' : 'none'), height: '100%' }}>
                    <BlankCard block={this.state.block} >
                        <Row className="mt-2 ">
                            <Col>
                                <h5><FormattedMessage id="EditVoucher.WhatsIncluded" /></h5>
                            </Col>
                            <Col className="text-right">
                            </Col>
                        </Row>
                        <hr className="my-1 border-host" />
                        <Row className="mb-2">
                            <Col className="col-5">
                                <h6><i className="fas fa-check text-success" /> <FormattedMessage id="EditVoucher.Included" /> </h6>
                            </Col>
                            <Col className="text-right">
                                <Authorization
                                    perform="cardConfigWizard:saveTermsIncluded"
                                    yes={() => (
                                        <Button className="btn-host btn-sm" onClick={this.saveTerms.bind(this, 'Included')}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                            <Col className="col-5">
                                <h6> <i className="fas fa-times text-danger" /> <FormattedMessage id="EditVoucher.NotIncluded" /></h6>
                            </Col>
                            <Col className="text-right">
                                <Authorization
                                    perform="cardConfigWizard:saveTermsExcluded"
                                    yes={() => (
                                        <Button className="btn-host btn-sm" onClick={this.saveTerms.bind(this, 'Excluded')}>
                                            <i className="fas fa-save" />
                                        </Button>
                                    )}
                                    no={() => <div></div>}
                                />
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col>
                                <MultiLangText textList={getTerm(this.state.voucherTermsList, "Included")} udpateTerms={this.updateIncludedTerm.bind(this)} />
                            </Col>
                            <Col>
                                <MultiLangText textList={getTerm(this.state.voucherTermsList, "Excluded")} udpateTerms={this.updateExcludedTerm.bind(this)} />
                            </Col>
                        </Row>
                    </BlankCard>
                </Card>
                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[5].active === true ? 'block' : 'none'), height: '100%' }}>
                    <BlankCard block={this.state.block} error={this.state.error}>
                        <Row className="mt-2 ">
                            <Col>
                                <h5><FormattedMessage id="EditVoucher.Images" /></h5>
                            </Col>
                            <Col className="text-right">
                            </Col>
                        </Row>
                        <hr className="my-1 border-host" />
                        <Row>
                            {
                                this.state.voucherImageList && this.state.voucherImageList.map((file, k) =>
                                    <Col className="col-3" key={k} onDragEnterCapture={() => this.setState({ endIdx: k })}>
                                        <Authorization
                                            perform="cardConfigWizard:deleteImage"
                                            yes={() => (
                                                <div className="text-right w-100">
                                                    <i className="fas fa-times text-danger" style={{ cursor: 'pointer' }} onClick={file && file.id ? this.deleteImage.bind(this, file.id) : ''} />
                                                </div>
                                            )}
                                            no={() => <div></div>}
                                        />
                                        <img src={file && file.url} alt="" style={{ maxWidth: '100%' }} draggable="true" onDragEnd={(e) => this.moveImages(e, k)} />
                                    </Col>
                                )
                            }
                            <Authorization
                                perform="cardConfigWizard:addImage"
                                yes={() => (
                                    <Col className="col-3 text-muted align-middle text-center m-1" style={{ border: '2px dashed' }} >
                                        <ImageDragAndDrop handleDrop={this.handleDrop}>
                                            <div style={{ height: 200, width: 250 }} />
                                        </ImageDragAndDrop>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                    </BlankCard>
                </Card>
                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[6].active === true ? 'block' : 'none'), height: '100%' }}>
                    <BlankCard block={this.state.block} >
                        <Row className="mt-2 ">
                            <Col>
                                <h5><FormattedMessage id="EditVoucher.Promote" /></h5>
                            </Col>
                            <Authorization
                                perform="cardConfigWizard:savePromote"
                                yes={() => (
                                    <Col className="text-right">
                                        <Button className="btn-host btn-sm" > <i className="fas fa-save " onClick={this.savePromote.bind(this)} /></Button>
                                    </Col>
                                )}
                                no={() => <div></div>}
                            />
                        </Row>
                        <hr className="my-1 border-host" />

                        <Row className="mb-2">
                            <Col>
                                <CustomInput type="switch" checked={this.state.voucherConfig.promote} label={<FormattedMessage id="EditVoucher.PromoteVoucher" />} name="promote" id="promote" onChange={this.handleSwitched.bind(this, 'voucherConfig')} />
                            </Col>
                            <Col>
                                {this.state.voucherConfig.promote === true ? <CustomSelect required options={getPromoteTypes()} value={getPromoteTypes().find(el => el.value === this.state.voucherConfig.promoteType)} name={'promoteType'} onChange={this.updateComboForm.bind(this, 'voucherConfig')} placeholder={<FormattedMessage id="CardConfigWizard.ChoosePromotionFlag" />} /> : ''}
                            </Col>
                        </Row>
                        <Row className="mb-2">
                            <Col className="col-2">
                                <FormattedMessage id="EditVoucher.PromoteCode" />
                            </Col>
                            <Col className="col-4">
                                <FormattedMessage id="EditVoucher.PromoteCode" >
                                    {
                                        placeholder => < Input type="text" maxLength="50" name="promoCode" value={this.state.voucherConfig.promoCode ? this.state.voucherConfig.promoCode : ''} onChange={this.updateForm.bind(this, 'voucherConfig')} placeholder={placeholder} ></Input>
                                    }
                                </FormattedMessage>
                            </Col>

                        </Row>
                    </BlankCard>
                </Card>

                <Card className="border-0" style={{ display: (this.state.steps && this.state.steps[7].active === true ? 'block' : 'none'), height: '100%' }}>
                    {this.state.steps && this.state.steps[7].active === true &&
                        <VoucherConfigStep8
                            voucherConfig={this.state.voucherConfig}
                            getNewVoucherRebate={this.getNewVoucherRebate}
                            getConfigDetails={this.getConfigDetails}
                        />
                    }
                </Card>
            </>

        );
    }
}

export default injectIntl(CardConfigWizard)