import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { Button, Card, CardBody, CardHeader, PopoverBody, UncontrolledPopover, Badge, Row, Col, Input, Modal, ModalBody } from 'reactstrap';
import { ErrorAlert } from "../../Base/ErrorAlert";
import { getHelpLink } from "./ReferenceDataFunctions"
import { getAPI } from "../../Base/API";
import CustomSelect from "../../Base/Common/CustomSelect";
import 'react-block-ui/style.css';
import { HandleHelp, HandleHelpClick } from '../../../Utils/EventGA';
import Chart from "react-google-charts";



export const StyledCard = ({ block, children, icon, title, help, error, id }) => (

    <Card className="shadow border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardHeader className="bg-white border-bottom-host">
                {icon ? <i className={icon + ' mr-1'}></i> : ''} {title ? <FormattedMessage id={title} /> : ''}

                {help ? <div className="float-right">
                    <span id="mainpagehelp" className="btn-sm btn-link" onClick={() => HandleHelp(id)} style={{ fontSize: 'medium' }}>
                        <i className="text-secondary fas fa-question-circle mt-1"/>
                    </span>
                    <UncontrolledPopover data-trigger="focus" placement="left" target="mainpagehelp">
                        <PopoverBody>
                            {help}
                            {getHelpLink(id) ?
                                <a className="float-right" href={getHelpLink(id)} target="_blank">
                                    <span onClick={_ => HandleHelpClick(id, getHelpLink(id))}>
                                        <FormattedMessage id="CommonUIComponents.MoreDetails" />
                                    </span>
                                </a>
                            : '' }
                        </PopoverBody>
                    </UncontrolledPopover>

                </div> : ''}

            </CardHeader>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>

    </Card>

);
export const BlankCard = ({ block, children, help, error, className, bodyClassName }) => (

    <Card className={className ? className : "shadow border-0 h-100"}>
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>

            <CardBody className={bodyClassName}>
                {children}
            </CardBody>
        </BlockUi>

    </Card>

);
export const EmptyCard = ({ block, children, error }) => (
    <Card className="border-0 h-100">
        <ErrorAlert error={error} />
        <BlockUi tag="div" blocking={block}>
            <CardBody>
                {children}
            </CardBody>
        </BlockUi>
    </Card>
);


export const SelectHotel = ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder = 'Hotel', isDisabled = false }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [ setError] = useState('');

    useEffect(() => {


        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                //setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data) {
                let arr = [];
                data.response[0].hotels.map((el) => { return arr.push({ value: el.hotelId, label: el.name2 }) });

                setData(arr);
                setLoad(false);

            }
        }, '/api/Voucher/Hotel/v1/hotel');

      
    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            <CustomSelect icon={icon} options={data} isClearable isSearchable required={required} name={name} placeholder={placeholder} isMulti={isMulti} value={data ? data.filter(el => el.value === value) : null} onChange={onChangeFunc.bind(this)} isDisabled={isDisabled}/>
        </BlockUi>
    )
};


export const SelectVoucherType= ({ icon, name, onChangeFunc, isMulti = false, required, value, placeholder = 'Card' }) => {

    const [data, setData] = useState([]);
    const [block, setLoad] = useState(true);
    const [setError] = useState('');

    useEffect(() => {


        getAPI(result => {
            const { data, error } = result;
            if (error) {
                var errorMessage = [];
                errorMessage.push({ message: error.message, stack: error.stack, messageType: 'danger' });
                //setError(errorMessage);
                setLoad(false)
                return;
            }
            if (data) {
                let arr = [];
                data.response.map((el) => { arr.push({ value: el.id, label: el.code }) })

                setData(arr);
                setLoad(false);

            }
        }, '/api/Voucher/V1/Config');


    }, []);

    return (
        <BlockUi tag="div" blocking={block}>
            <CustomSelect icon={icon} options={data} isClearable isSearchable required={required} name={name} placeholder={placeholder} isMulti={isMulti} value={data ? data.filter(el => el.value === value) : null} onChange={onChangeFunc.bind(this)} />
        </BlockUi>
    )
};


export const ActiveInactiveStatusCombo = ({ name, onChangeFunc, required = true, value, placeholder, isDisabled, isClearable }) => {
    const statusOptions = [
        { value: true, label: <FormattedMessage id="generic.active" /> },
        { value: false, label: <FormattedMessage id="generic.inactive" /> }
    ]

    return (
        <CustomSelect
            placeholder={placeholder}
            options={statusOptions}
            onChange={onChangeFunc.bind(this, name)}
            required={required}
            value={statusOptions.find(el => el.value === value)}
            isDisabled={isDisabled}
            isSearchable
            isClearable={isClearable}
        />
    )
};

export const ActiveInactiveStatusBadge = ({ status }) => (
    <Badge color={status ? 'primary' : 'secondary'} className={(status ? 'p-1 px-2 shadow  pr-3' : ' p-1 px-2 shadow ')}>{status ? <FormattedMessage id="generic.active" /> : <FormattedMessage id="generic.inactive" />}</Badge>
);

export const CommonHelper = ({ help, id, placement = 'left' }) => {
    const helpLink = getHelpLink(id);

    return (
        <div className="float-right">
            <span id={id} className="btn-sm btn-link" onClick={() => HandleHelp(id)} style={{ fontSize: 'medium' }}>
                <i className="text-secondary fas fa-question-circle mt-2 "></i>
            </span>
            <UncontrolledPopover data-trigger="focus" placement={placement} target={id}>
                <PopoverBody>
                    <div className="mb-1">
                        {help}
                    </div>
                    <a className="float-right" href={helpLink} target="_blank">
                        <span onClick={_ => HandleHelpClick(id, helpLink)}>
                            <FormattedMessage id="CommonUIComponents.MoreDetails" />
                        </span>
                    </a>
                </PopoverBody>
            </UncontrolledPopover>
        </div>
    )
};

export const countries = [
    'PT',
    'GB',
    'FR',
    'DE',
];

export function getLanguageOptions() {
    const languageOptions = [
        {
            value: 'PT',
            label: <span> <span className="flag-icon flag-icon-pt mb-2 px-2 mr-1" /> <FormattedMessage id="MultiLang.Portuguese" /></span>
        },
        {
            value: 'EN',
            label: <span> <span className="flag-icon flag-icon-gb mb-2 px-2 mr-1" /> <FormattedMessage id="MultiLang.English" /></span>
        },
        {
            value: 'FR',
            label: <span> <span className="flag-icon flag-icon-fr mb-2 px-2 mr-1" /> <FormattedMessage id="MultiLang.French" /></span>
        },
        {
            value: 'DE',
            label: <span> <span className="flag-icon flag-icon-de mb-2 px-2 mr-1" /> <FormattedMessage id="MultiLang.German" /></span>
        }
    ];
    return languageOptions;
}


export function getMultiLangEmpty() {
    const multiLangEmpty = [
        {
            languageCode: "PT",
            language: "PT",
            title: null,
            description: null
        },
        {
            languageCode: "EN",
            language: "GB",
            title: null,
            description: null
        },
        {
            languageCode: "FR",
            language: "FR",
            title: null,
            description: null
        },
        {
            languageCode: "DE",
            language: "DE",
            title: null,
            description: null
        }
    ];
    return multiLangEmpty;
}

export const MultiLangTextWithTitle = ({ textList, updateTitle, udpateDescription }) => {
    const [lang, setLang] = useState(countries[0]);

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const text = textList && textList.find(t => t.language === lang);

    return (
        <MultiLang textList={textList} udpateTerms={udpateDescription} toggle={toggle} lang={lang}>
            <div>
                <Row className="mb-2">
                    <Col>
                        <FormattedMessage id="MultiLang.Title">
                            {
                                placeholder => <Input type="text" style={{ border: 'none' }} className="px-2" name="title" id="title"  placeholder={placeholder} value={text && text.title ? text.title : ''} onChange={e => updateTitle(e, lang)} />
                            }
                        </FormattedMessage>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <FormattedMessage id="MultiLang.Description">
                            {
                                placeholder => <Input className="px-2" style={{ border: 'none' }} type="textarea" placeholder={placeholder} rows="4" max="3500" name="description" value={text && text.description ? text.description : ''} required onChange={(e) => udpateDescription(e, lang)} />
                            }
                        </FormattedMessage>
                    </Col>
                </Row>
            </div>
        </MultiLang>
    );
};

export const MultiLangText = ({ textList, udpateTerms }) => {
    const [lang, setLang] = useState(countries[0]);

    const toggle = l => {
        if (lang !== l) setLang(l);
    }

    const text = textList && textList.find(t => t.language === lang);

    return (
        <MultiLang textList={textList} udpateTerms={udpateTerms} toggle={toggle} lang={lang}>
            <FormattedMessage id="MultiLang.Description" >
                {
                    placeholder => <Input className="px-2" style={{ border: 'none' }} type="textarea" placeholder={placeholder} rows="4" max="3500" name="description" value={text && text.description ? text.description : ''} required onChange={(e) => udpateTerms(e, lang)} disabled={!udpateTerms} />
                }
            </FormattedMessage>
        </MultiLang>
    );
};

const MultiLang = ({ toggle, lang, children }) => {
    return (
        <Row>
            <Col style={{ maxWidth : '75px' }} className={` pr-0`}>
                { countries.map((country, key) =>
                    <div key={key} className={"bg-white p-2 " + (lang === country ? 'border-left-host-bold border-top border-bottom' : 'border')} style={{
                        position: "relative",
                        right: "-1px",
                        cursor: "pointer"
                    }} onClick={() => toggle(country)}>
                        <div className={`flag-icon flag-icon-${country.toLowerCase()} mb-2 px-2`} />
                    </div>
                )}
            </Col>
            <Col className={` pl-0`}>
                <div className="border">
                    {children}
                </div>
            </Col>
        </Row>
    );
};

export function prettifyXml(sourceXml) {
    var xmlDoc = new DOMParser().parseFromString(sourceXml, 'application/xml');
    var xsltDoc = new DOMParser().parseFromString([
        // describes how we want to modify the XML - indent everything
        '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
        '  <xsl:strip-space elements="*"/>',
        '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
        '    <xsl:value-of select="normalize-space(.)"/>',
        '  </xsl:template>',
        '  <xsl:template match="node()|@*">',
        '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
        '  </xsl:template>',
        '  <xsl:output indent="yes"/>',
        '</xsl:stylesheet>',
    ].join('\n'), 'application/xml');

    var xsltProcessor = new XSLTProcessor();
    xsltProcessor.importStylesheet(xsltDoc);
    var resultDoc = xsltProcessor.transformToDocument(xmlDoc);
    var resultXml = new XMLSerializer().serializeToString(resultDoc);
    return resultXml;
};


export const processStatusValues = [
    'MessageProcessStatusIsPending',
    'MessageProcessStatusIsResolved'
];


export const paymentStatus = [
    {
        value: 'Payed',
        label: <FormattedMessage id="ReferenceDataFunctions.Payed" />,
        color: 'success'
    },
    {
        value: 'Requested',
        label: <FormattedMessage id="ReferenceDataFunctions.Requested" />,
        color: 'warning'
    },
    {
        value: 'Canceled',
        label: <FormattedMessage id="ReferenceDataFunctions.Canceled" />,
        color: 'danger'
    }
];

export const PaymentPositionStatus = ({ status }) => {
    var currentStatus = paymentStatus.find(s => s.value === status);

    return <Badge color={currentStatus ? currentStatus.color : 'danger'} className={'p-1 px-2 shadow'} style={{ color: 'white' }}>{currentStatus ? currentStatus.label : ''}</Badge>
};

export const CancelationModal = ({ isOpen, toggle, exitFunc}) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalBody>
                <EmptyCard>
                    <Row className="mb-3">
                        <Col>
                            <h5>
                                <FormattedMessage id="generic.cancelOperationQuestion" />
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="btn btn-host btn-sm" onClick={toggle}>
                                <FormattedMessage id="generic.cancel" />
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button className="btn btn-host btn-s" onClick={exitFunc}>
                                <FormattedMessage id="generic.exit" />
                            </Button>
                        </Col>
                    </Row>
                </EmptyCard>
            </ModalBody>
        </Modal>
    );
}


export const Crt = ({ text, value }) => {
    return <Card className="shadow h-100 py-2 px-3" body>
        <Row className="align-items-center">
            <Col>
                <div className="text-secondary mb-1">
                    {text}
                </div>
            </Col>
        </Row>
        <Row>
            <Col className="text-right">
                <b className="font-weight-bold text-secondary mb-1">
                    {value}
                </b>
            </Col>
        </Row>
    </Card>
}


export const ChartCard = ({ title, chartType, options, data, total, fullScreen, fullScreenFunction, height }) => {
    return (
        <Card body className="shadow border-0 h-100" >
            <Row>
                <Col className="text-xs font-weight-bold text-secondary text-uppercase mb-1">{title}</Col>
                {total ?
                    <Col className="text-xs font-weight-bold text-secondary text-right mb-1">{total}</Col>
                    : ''}
                {fullScreen ?
                    <Col className="text-right col-1">
                        <i onClick={fullScreenFunction} className="fas fa-expand-arrows-alt"></i>
                    </Col>
                    : ''}
            </Row>
            <Row>
                {data && data.length > 0 ?
                    <Chart
                        chartType={chartType}
                        width="100%"
                        height={height ? height : '200px'}
                        data={data}
                        options={options}
                    />
                    : ''}
            </Row>
        </Card>
    )
}


export const FormatAmountNumber = ({ value, currency, avoidDecimals }) => {
    return <FormattedNumber
        value={value}
        maximumFractionDigits={avoidDecimals && value && value % 1 == 0 ? 0 : 2}
        style="currency"
        currency={currency || global.hotelCurrency}
    />
}